<script setup>
import HrbrButton from '@/components/ui/HrbrButton.vue';
import { onMounted, reactive, ref, watch } from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const props = defineProps({
  value: {
    type: [Array, String],
    default: () => [],
  },
  disabled: {
    type: Boolean
  },
  isRange: {
    type: Boolean,
    default: false
  },
  hasShortcuts: {
    type: Boolean,
    default: false
  },
  editable: {
    type: Boolean,
    default: true
  },
  iconOnly: {
    type: Boolean,
    default: false
  },
  isOpen: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['input', 'change']);

const date = ref(props.value);
const open = ref(false);
const inputSize = ref(6);
const popupClass = ref('');
const lastSelectedShortcut = ref(null);

const shortcutsDatesMap = new Map([
  ['all', () => [null, null]],
  ['today', () => [new Date(), new Date()]],
  [
    'yesterday',
    () => {
      const date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24);
      return [date, date];
    },
  ],
  [
    'last7',
    () => {
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - 6));
      return [priorDate, today];
    },
  ],
  [
    'last30',
    () => {
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - 29));
      return [priorDate, today];
    },
  ],
  [
    'thismonth',
    () => {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return [firstDay, lastDay];
    },
  ],
  [
    'lastmonth',
    () => {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
      return [firstDay, lastDay];
    },
  ],
]);
const shortcuts = [
  {
    text: 'All time',
    value: 'all',
    onClick: (emit) => {
      popupClass.value = '';
      lastSelectedShortcut.value = 'all';
      emit([null, null]);
      open.value = false;
    },
  },
  {
    text: 'Today',
    value: 'today',
    onClick: (emit) => {
      popupClass.value = '';
      lastSelectedShortcut.value = 'today';
      emit([new Date(), new Date()]);
      open.value = false;
    },
  },
  {
    text: 'Yesterday',
    value: 'yesterday',
    onClick: (emit) => {
      popupClass.value = '';
      lastSelectedShortcut.value = 'yesterday';
      const date = shortcutsDatesMap.get('yesterday')();
      emit(date);
      open.value = false;
    },
  },
  {
    text: 'Last 7 days',
    value: 'last7',
    onClick: (emit) => {
      popupClass.value = '';
      lastSelectedShortcut.value = 'last7';
      const date = shortcutsDatesMap.get('last7')();
      emit(date);
      open.value = false;
    },
  },
  {
    text: 'Last 30 days',
    value: 'last30',
    onClick: (emit) => {
      popupClass.value = '';
      lastSelectedShortcut.value = 'last30';
      const date = shortcutsDatesMap.get('last30')();
      emit(date);
      open.value = false;
    },
  },
  {
    text: 'This month',
    value: 'thismonth',
    onClick: (emit) => {
      popupClass.value = '';
      lastSelectedShortcut.value = 'thismonth';
      const date = shortcutsDatesMap.get('thismonth')();
      emit(date);
      open.value = false;
    },
  },
  {
    text: 'Last month',
    value: 'lastmonth',
    onClick: (emit) => {
      popupClass.value = '';
      lastSelectedShortcut.value = 'lastmonth';
      const date = shortcutsDatesMap.get('lastmonth')();
      emit(date);
      open.value = false;
    },
  },
  {
    text: 'Custom range',
    value: 'custom',
    onClick: () => {
      popupClass.value = 'custom';
    },
  },
];

const cancelButton = reactive({
  show: true,
  text: 'Cancel',
  action: () => (open.value = false),
});

watch(() => props.isOpen, (value) => {
  open.value = value;
});

onMounted(() => {
  if (props.hasShortcuts) compareShortcutsToSelection(date.value);
  if (!props.iconOnly) inputSize.value = date.value.length;
});

const onRangeConfirm = (evt) => {
  compareShortcutsToSelection(evt);
};

const compareShortcutsToSelection = (range) => {
  if (!range[0] && !range[1]) {
    lastSelectedShortcut.value = 'all';
    popupClass.value = '';
    return;
  }
  for (let [key, value] of shortcutsDatesMap.entries()) {
    const shortcutVal = value();
    if (
      range[0].setHours(0, 0, 0, 0) === shortcutVal[0]?.setHours(0, 0, 0, 0) &&
      range[1].setHours(0, 0, 0, 0) === shortcutVal[1]?.setHours(0, 0, 0, 0)
    ) {
      lastSelectedShortcut.value = key;
      popupClass.value = '';
      return;
    }
  }
  lastSelectedShortcut.value = 'custom';
  popupClass.value = 'custom';
}
const onValueChanged = (evt) => {
  if (props.hasShortcuts) compareShortcutsToSelection(evt);
  emit('input', evt);
  emit('change', evt);
};

// Fires when User input single date for range
const onErrorInput = (evt) => {
  const dates = Array.isArray(evt) ? evt.replaceAll(' ', '').split('-') : [evt];
  const isDateValid = !isNaN(new Date(dates[0]));
  if (dates.length === 1 && isDateValid) {
    date.value = [new Date(dates[0]), new Date(dates[0])];
    compareShortcutsToSelection([new Date(dates[0]), new Date(dates[0])]);
  }
};

const onValuePicked = () => {
  if (props.hasShortcuts) popupClass.value = 'custom';
};

const getInputValue = (props) => {
  if (lastSelectedShortcut.value !== 'custom') {
    const shortcutText = shortcuts.find(item => item.value === lastSelectedShortcut.value);
    inputSize.value = lastSelectedShortcut.value === 'all' ? 6 : shortcutText?.text.length || props.value.length;
    return shortcutText?.text || props.value;
  }
  inputSize.value = props.value.length;
  return props.value;
};

const onInputKeydown = (evt, events) => {
  lastSelectedShortcut.value = '';
  events.keydown(evt);
};

const formatter = reactive({
  parse: (value) => {
    return new Date(value);
  },
});
</script>

<template>
  <div
    :class="[
      'hrbr-datepicker',
      props.disabled ? 'disabled' : '',
      props.iconOnly ? 'icon-only' : ''
    ]"
  >
    <date-picker
      v-model="date"
      :open.sync="open"
      :popup-class="popupClass"
      :confirm="popupClass === 'custom'"
      :formatter="formatter"
      :editable="editable"
      :range="isRange"
      confirm-text="Update"
      range-separator=" - "
      format="MMM DD YYYY"
      @confirm="onRangeConfirm"
      @change="onValueChanged"
      @pick="onValuePicked"
      @input-error="onErrorInput"
    >
      <template #icon-calendar>
        <span></span>
      </template>
      <template
        v-slot:input="{ props, events }"
      >
        <span
          v-if="iconOnly"
          class="datepicker-icon"
        >
          <i class="fa-regular fa-calendar"></i>
        </span>
        <input
          v-else
          :value="getInputValue(props)"
          :size="inputSize"
          class="datepicker-input"
          type="text"
          v-on="events"
          @keydown="(evt) => onInputKeydown(evt, events)"
        >
      </template>
      <template
        v-if="hasShortcuts"
        v-slot:sidebar="{ emit }"
      >
        <div class="datepicker-sidebar-wrap">
          <div
            v-for="item in shortcuts"
            :class="['datepicker-sidebar-item', lastSelectedShortcut === item.value && 'active']"
            @click="item.onClick(emit)">
            {{ item.text }}
          </div>
        </div>
      </template>

      <template
        v-if="hasShortcuts"
        #footer
      >
        <HrbrButton :button="cancelButton" />
      </template>
    </date-picker>
  </div>
</template>

<style lang="postcss">
@import '@/assets/css/elements/datepicker-global.css';
.datepicker-sidebar-wrap {
  padding: 13px 0;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.datepicker-sidebar-item {
  padding: 7px 20px;
  color: #333333;
  font-weight: 400;
  cursor: pointer;

  &.active, &:hover {
    background-color: #BCCFFF;
  }
}
.datepicker-input {
  font-weight: 500;
  color: #333 !important;
}
@media (max-width: 640px) {
  .datepicker-sidebar-wrap {
    border: none;
  }
  .datepicker-sidebar-item {
    padding: 7px 12px;
  }
}
</style>
